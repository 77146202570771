import React,{forwardRef,useImperativeHandle,useState,useRef} from 'react'
import { makeStyles, withStyles} from '@material-ui/core/styles';
import { hBox,vBox } from "gx-web-ui/utils/flex";
import compose from 'recompose/compose'
import {inject,observer} from 'mobx-react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import classnames from 'classnames'
import FilterPriceSlider from './FilterPriceSlider'
import FilterSettingRangeSlider from './FilterSettingRangeSlider'
import FilterLandSizeSlider from './FilterLandSizeSlider'
import CheckBoxGroup from './CheckBoxGroup'
import FilterSelectBtnGroup from './FilterSelectBtnGroup'
import PriceRangeGroup from './PriceRangeGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PrimaryCheckBox from '@src/components/common/PrimaryCheckBox'
import max from 'lodash/max'
import min from 'lodash/min'


const TypeBtn = withStyles(theme=>({
    root:{
        textTransform:'none',
        borderColor:theme.palette.grey[400],
        color:theme.palette.grey[400],
        margin:4,
    }
}))(Button)

const FilterView = (props,ref) => {
    const { filterStore, style, hideBuyRent=false } = props
    const {
        FILTER_BEDROOMS,
        RENT_PRICE_RANGE,
        BUY_PRICE_RANGE,
        FILTER_BATHROOMS,
        FILTER_PARKINGS,
        isRentFilter,
        rentFeatures,
        buyFeatures,
        clearFilter,
        FILTER_BUY_RENT_TYPE,
        setCurrentFilterType,
    } = filterStore


    const styles = useStyles()
    //price 
    // const defaultPriceRange = isRentFilter?filterStore.rentPriceRange:filterStore.priceRange
    // const priceList = (isRentFilter?RENT_PRICE_RANGE:BUY_PRICE_RANGE).map(item=>item.value)
    // const sliderRange={min:0,max:max(priceList)}
    // const [priceRange, setPriceRange] = useState([defaultPriceRange.min||sliderRange.min,defaultPriceRange.max||sliderRange.max])
    const defaultPriceRange = isRentFilter?filterStore.rentPriceRange:filterStore.priceRange
    const [contentPriceRange, setContentPriceRange] = useState({
        min:defaultPriceRange.min||'any',
        max:defaultPriceRange.max||'any',
    })

    //landSize
    const landSizeSliderRange = {min:0,max:500}
    const [landSizeRange, setLandSizeRange] = useState([filterStore.landSizeRange.min||landSizeSliderRange.min,filterStore.landSizeRange.max||landSizeSliderRange.max])

    
    //property type
    const [propertyType, setPropertyType] = useState(()=>{
        if(filterStore.propertyType.length===0){
            return []
        }else{
            return filterStore.propertyType
        }
    })
    const onClickPropertyType = (code)=>{
        if(code ==='ANY'){
            setPropertyType([code])
        }else{
            const removeAny = propertyType.filter(o=>o!=='ANY')
            if(propertyType.includes(code)){
                setPropertyType(removeAny.filter(o=>o!==code))
            }else{
                setPropertyType([...removeAny,code])
            }
        }
    }

    // beds baths parking
    const [bedrooms, setBedrooms] = useState(filterStore.bedrooms)
    const [bathrooms, setBathrooms] = useState(filterStore.bathrooms)
    const [parkings, setParkings] = useState(filterStore.parkings)

    // features 
    const featuresItems = isRentFilter?rentFeatures:buyFeatures
    const [features, setFeatures] = useState(filterStore.features)
    
    //additional Setting
    const [inspectionAuctionFilterChecked, setInspectionAuctionFilterChecked] = useState(filterStore.inspectionAuctionFilterChecked)
    const [excludeUnderOffer, setExcludeUnderOffer] = useState(filterStore.excludeUnderOffer)
    const [excludeOffThePlan, setExcludeOffThePlan] = useState(filterStore.excludeOffThePlan)
    
    //type 
    const [filterType, setFilterType] = useState(filterStore.currentFilterType)
    const isCurrentSelectedRentFilter = filterType === 'rent'
    
    //slider refs
    const bedRef = useRef(null)
    const bathRef = useRef(null)
    const parkingRef = useRef(null)

    // const reset = ()=>{
    //     setPriceRange([defaultPriceRange.min||sliderRange.min,defaultPriceRange.max||sliderRange.max])
    //     setLandSizeRange([filterStore.landSizeRange.min||landSizeSliderRange.min,filterStore.landSizeRange.max||landSizeSliderRange.max])
    //     setPropertyType(filterStore.propertyType.length===0?['ANY']:filterStore.propertyType)
    //     setBedrooms(filterStore.bedrooms)
    //     setBathrooms(filterStore.bathrooms)
    //     setParkings(filterStore.parkings)
    //     setFeatures(filterStore.features)
    //     setInspectionAuctionFilterChecked(filterStore.inspectionAuctionFilterChecked)
    //     setExcludeUnderOffer(filterStore.excludeUnderOffer)
    //     setExcludeOffThePlan(filterStore.excludeOffThePlan)
    //     setFilterType(filterStore.filterType)
    //     bedRef.current.reset(filterStore.bedrooms)
    //     bathRef.current.reset(filterStore.bathrooms)
    //     parkingRef.current.reset(filterStore.parkings)
    // }

    React.useEffect(() => {
        const defaultPriceRange = isCurrentSelectedRentFilter?filterStore.rentPriceRange:filterStore.priceRange
        setContentPriceRange({
            min:defaultPriceRange.min||'any',
            max:defaultPriceRange.max||'any',
        })
    }, [filterType])


    useImperativeHandle(ref, () => ({
        save:()=>{
            const {
                setRentPriceRange,
                setPriceRange,
                setLandSizeRange
            } = filterStore
            const priceRangeSetter = isCurrentSelectedRentFilter?setRentPriceRange:setPriceRange
            // const minV = min(priceRange)
            // const maxV = max(priceRange)
            priceRangeSetter({
                min:contentPriceRange.min==='any'?null:contentPriceRange.min,
                max:contentPriceRange.max==='any'?null:contentPriceRange.max,
            })
            // priceRangeSetter({
            //     min:minV===0?null:minV,
            //     max:maxV===sliderRange.max?null:maxV
            // })
            const minLandSize = min(landSizeRange)
            const maxLandSize = max(landSizeRange)
            setLandSizeRange({
                min:minLandSize===0?null:minLandSize,
                max:maxLandSize===landSizeSliderRange.max?null:maxLandSize
            })
            filterStore.setPropertyType(propertyType)
            filterStore.setBedrooms(bedrooms)
            filterStore.setBathRooms(bathrooms)
            filterStore.setParkings(parkings)
            filterStore.setFeatures(features)
            filterStore.setInspectionAuctionFilterChecked(inspectionAuctionFilterChecked)
            filterStore.setExcludeUnderOffer(excludeUnderOffer)
            filterStore.setExcludeOffThePlan(excludeOffThePlan)
            filterStore.setCurrentFilterType(filterType)
            console.log('saved')
        },
        // reset,
        clear:()=>{
            console.log('clear')
            // clearFilter()
            // setPriceRange([sliderRange.min,sliderRange.max])
            setContentPriceRange({
                min:'any',
                max:'any',
            })
            setLandSizeRange([landSizeSliderRange.min,landSizeSliderRange.max])
            setPropertyType([])
            setBedrooms({
                from:0,
                to:5
            })
            setBathrooms({
                from:1,
                to:6
            })
            setParkings({
                from:0,
                to:5
            })
            setFeatures([])
            setInspectionAuctionFilterChecked(false)
            setExcludeUnderOffer(false)
            setExcludeOffThePlan(false)
            setCurrentFilterType('newDevelopment')
            bedRef.current.reset({
                from:0,
                to:5
            })
            bathRef.current.reset({
                from:1,
                to:6
            })
            parkingRef.current.reset({
                from:0,
                to:5
            })
        }
    }));
    return (
        <div className={styles.root} style={style}>
            {!hideBuyRent &&
            <div style={{marginBottom:16}}>
                <FilterSelectBtnGroup options={FILTER_BUY_RENT_TYPE} value={filterType} setValue={setFilterType}/>
            </div>
            }
            <Typography className={styles.title}>Property type</Typography>
            <div className={styles.propertyTypeContainer}>
                {filterStore.filterPropertyTypes.map(item=>{
                    return <TypeBtn 
                        key={item.title} 
                        variant="outlined" 
                        className={classnames({[styles.btnSelected]:propertyType.includes(item.code)})} 
                        onClick={()=>onClickPropertyType(item.code)}
                    >
                        {item.title}
                    </TypeBtn>
                })}
            </div>
            <div className={styles.divider}/>
            <Typography className={styles.title}>Price range</Typography>
            {/* <FilterPriceSlider 
                priceRange={priceRange}
                setPriceRange={setPriceRange}
                range={sliderRange}
                step={isRentFilter?500:50000}
            /> */}
            <PriceRangeGroup
                selectOptions={isCurrentSelectedRentFilter?RENT_PRICE_RANGE:BUY_PRICE_RANGE}
                priceRange={contentPriceRange}
                setPriceRange={setContentPriceRange}
            />
            <div className={styles.divider}/>
            <Typography className={styles.title}>Bedrooms</Typography>
            <FilterSettingRangeSlider options={FILTER_BEDROOMS} values={bedrooms} setValues={setBedrooms} ref={bedRef}/>
            <div className={styles.divider}/>
            <Typography className={styles.title}>Bathrooms</Typography>
            <FilterSettingRangeSlider options={FILTER_BATHROOMS} values={bathrooms} setValues={setBathrooms} ref={bathRef}/>
            <div className={styles.divider}/>
            <Typography className={styles.title}>Parking</Typography>
            <FilterSettingRangeSlider options={FILTER_PARKINGS} values={parkings} setValues={setParkings} ref={parkingRef}/>
            <div className={styles.divider}/>
            <Typography className={styles.title}>Land size</Typography>
            <FilterLandSizeSlider
                rangeValue={landSizeRange}
                setRangeValue={setLandSizeRange}
                range={landSizeSliderRange}
                step={1}
            />
            <div className={styles.divider}/>
            <Typography className={styles.title}>Features</Typography>
            <CheckBoxGroup
                items={featuresItems}
                values={features}
                setValues={setFeatures}
            />
            <div className={styles.divider}/>
            <Typography className={styles.title}>Others</Typography>
            <div style={{...vBox('start')}}>
                <FormControlLabel
                    control={<PrimaryCheckBox checked={inspectionAuctionFilterChecked} onChange={e=>setInspectionAuctionFilterChecked(e.target.checked)} name={'inspection'} />}
                    label={'Inspection / auction'}
                />
                <FormControlLabel
                    control={<PrimaryCheckBox checked={excludeUnderOffer} onChange={e=>setExcludeUnderOffer(e.target.checked)} name={'excludeUnderOffer'} />}
                    label={'Exclude under offer'}
                />
                <FormControlLabel
                    control={<PrimaryCheckBox checked={excludeOffThePlan} onChange={e=>setExcludeOffThePlan(e.target.checked)} name={'excludeOffThePlan'} />}
                    label={'Exclude off the plan'}
                />
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme=>({
    title:{
        paddingBottom:16,
        fontSize:18,
        opacity:0.5,
        fontWeight:500
    },
    root:{
        minWidth:400
    },
    btnSelected:{
        borderColor:theme.palette.primary.main,
        color:theme.palette.primary.main,
    },
    propertyTypeContainer:{
        marginLeft:-4
    },
    divider:{
        paddingTop:16,
        paddingBottom:16
    },
    rangeSlider:{
        marginLeft:4,
        marginRight:4
    }
}))

export default compose(
    inject('filterStore'),
    observer,
    forwardRef
)(FilterView)
