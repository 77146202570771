import React from 'react'
import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';

export default withStyles(theme=>({
    root:{
        marginLeft:6,
        width:'98%'
    },
    rail: {
        color: theme.palette.grey[200],
        opacity: 1,
        height: 2,
    },
    track:{
        color: theme.palette.grey[400],
        height: 2,
    }
}))(Slider)
