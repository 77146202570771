import React from 'react'
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { withStyles,makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames'


const GroupBtn = withStyles(theme=>({
    root:{
        textTransform:'none',
        whiteSpace:'nowrap',
        // borderColor:theme.palette.grey[200]
    }
}))(Button)

const BtnGroup = withStyles(theme=>({
    groupedOutlinedPrimary:{
        borderColor:theme.palette.grey[200]
    },
    groupedOutlinedHorizontal:{
        "&:not(:last-child)":{
            borderRightColor:theme.palette.primary.main,
        },
        "&:hover":{
            zIndex:2,
            borderColor:theme.palette.primary.main,
        }
    }
}))(ButtonGroup)

const FilterSelectBtnGroup = (props) => {
    const {
        options=[],
        value,
        setValue
    } = props
    const onClickBtn=(index)=>()=>{
        setValue(options[index].value)
    }
    const styles = useStyles()
    return (
    <BtnGroup color="primary">
        {options.map((item,index)=>
            <GroupBtn onClick={onClickBtn(index)} className={classnames({[styles.selected]:value===item.value})} style={{width:120}} key={item.title}>
                {item.title}
            </GroupBtn>
        )}
    </BtnGroup>
    )
}

const useStyles = makeStyles(theme=>({
    selected:{
        borderColor:'black',
        borderWidth:1,
        zIndex:2,
        "&:hover":{
            backgroundColor:theme.palette.grey[200],
        },
    }
}))

export default FilterSelectBtnGroup
