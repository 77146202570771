import React,{useRef,useEffect,useState,useImperativeHandle,forwardRef} from 'react'
import { withStyles,makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { hBox,vBox } from "gx-web-ui/utils/flex";
import { motion, useMotionValue, useAnimation, useTransform } from "framer-motion"
import every from 'lodash/every'
import round from 'lodash/round'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'

const draggerSize = 46
const longDraggerSize = 64
const Slider = props=>{
    const { value, dragConstraints,controls,x, closestX,onDragEndCb} = props
    const styles = useStyles()
    const onDragEnd=(event,info)=>{
        const cx = closestX(x.get())
        controls.start({
            x:cx
        })
        if(onDragEndCb){
            onDragEndCb(cx)
        }
    }
    return <motion.div 
            drag='x' 
            style={{x}}
            animate={controls}
            className={styles.slider} 
            dragConstraints={dragConstraints} 
            dragElastic={0.00001} 
            dragMomentum={false}
            onDragEnd={onDragEnd}
        >
        <Typography className={styles.sliderText}>{value}</Typography>
    </motion.div>
}

const FilterSettingRangeSlider = (props,ref) => {
    const {
        values,
        setValues,
        options,
    } = props
    const styles = useStyles()
    const containerRef = useRef(null)
    const positions = useRef(Array(options.length).fill(null))
    const finalPosition = useRef(Array(options.length).fill(null))
    const [isDivided, setIsDivided] = useState(false)
    const xl = useMotionValue(0)
    const xr = useMotionValue(0)
    const controlsl = useAnimation()
    const controlsr = useAnimation()

    const pl = useTransform([xl,xr],([l,r])=>Math.min(l,r) + 23)
    const pw = useTransform([xl,xr],([l,r])=>Math.max(l,r) - Math.min(l,r))

    const [xlTitle, setxlTitle] = useState('')
    const [xrTitle, setxrTitle] = useState('')

    const closest = (x)=>{
        const sorted = sortBy(finalPosition.current,(o) => Math.abs(o.x-x))
        return sorted[0]
    }
    const closestX = (point)=>{
        return closest(point).x
    }

    const onDragEnd = (controls) =>(x)=>{
        const item = closest(x)

        // animate style
        if(item.title.length>4){
            controls.start({
                width:longDraggerSize,
                transition:{
                    duration:0.2
                }
            })
        }else{
            controls.start({
                width:draggerSize,
                transition:{
                    duration:0.2
                }
            })
        }

        //set values
        const l = closest(xl.get())
        const r = closest(xr.get())
        const from = l.x<r.x?l.value:r.value
        const to = l.x<r.x?r.value:l.value
        setValues({
            from,
            to
        })
        
    }
    const getCurrentSliderText =(x)=>{
        if(every(finalPosition.current,item=>item!==null)){
            return closest(x).title
        }
    }

    useImperativeHandle(ref,() => ({
        reset:(values)=>{
            const xlData = find(finalPosition.current,o=>o.value===values.from)
            const xrData = find(finalPosition.current,o=>o.value===values.to)
            controlsl.start({
                x:xlData.x,
                width:xlData.title.length>4?longDraggerSize:draggerSize,
                transition:{
                    ease:'easeInOut'
                }
            })
            controlsr.start({
                x:xrData.x,
                width:xrData.title.length>4?longDraggerSize:draggerSize,
                transition:{
                    ease:'easeInOut'
                }
            })
        }
    }))

    const onClickBtn = (item)=>{
        // console.log(item)
        const targetData = find(finalPosition.current,o=>o.value===item.value)
        controlsl.start({
            x:targetData.x,
            width:targetData.title.length>4?longDraggerSize:draggerSize,
            transition:{
                ease:'easeInOut'
            }
        })
        controlsr.start({
            x:targetData.x,
            width:targetData.title.length>4?longDraggerSize:draggerSize,
            transition:{
                ease:'easeInOut'
            }
        })
        setValues({
            from:targetData.value,
            to:targetData.value
        })
    }

    useEffect(() => {
        if(containerRef.current && every(positions.current,item=>item!==null)){
            const divx = containerRef.current?.getBoundingClientRect().x
            setIsDivided(true)
            finalPosition.current = positions.current.map(item=>({
                ...item,
                x:round(item.x-divx)
            }))

            // init default x position and style
            const xlData = find(finalPosition.current,o=>o.value===values.from)
            xl.set(xlData?.x)
            setxlTitle(xlData?.title)
            if(xlData.title.length > 4){
                controlsl.start({
                    width:longDraggerSize,
                    transition:{
                        duration:0.2
                    }
                })
            }
            const xrData = find(finalPosition.current,o=>o.value===values.to)
            xr.set(xrData?.x)
            setxrTitle(xrData?.title)
            if(xrData.title.length > 4){
                controlsr.start({
                    width:longDraggerSize,
                    transition:{
                        duration:0.2
                    }
                })
            }

        }
    }, [containerRef.current,positions.current])

    const getAndSetCenterPositionByRef=(index)=>(el)=>{
        if(el){
            const bounding = el.getBoundingClientRect()
            positions.current[index] = {
                x:bounding.x - 10,
                ...options[index]
            }
        }
    }
    useEffect(() => {
        xl.onChange(x=>{
            setxlTitle(getCurrentSliderText(x))
        })
        xr.onChange(x=>{
            setxrTitle(getCurrentSliderText(x))
        })
    }, [])
    return (
        <div className={styles.container}>
            <motion.div className={styles.range} style={{left:pl,width:pw}}/>
            <motion.div className={styles.root} ref={containerRef}>
                {options.map((item,index)=>
                <Typography 
                    key={item.title} 
                    className={styles.text}
                    ref={getAndSetCenterPositionByRef(index)}
                    align='center'
                    onClick={()=>onClickBtn(item)}
                >
                    {item.title}
                </Typography>
                )}
            </motion.div>
            <Slider value={xlTitle} dragConstraints={containerRef} closestX={closestX} controls={controlsl} x={xl} onDragEndCb={onDragEnd(controlsl)}/>
            <Slider value={xrTitle} dragConstraints={containerRef} closestX={closestX} controls={controlsr} x={xr} onDragEndCb={onDragEnd(controlsr)}/>
        </div>
    )
}

const useStyles = makeStyles(theme=>({
    container:{
        minWidth:420,
        paddingLeft:8,
        paddingRight:8,
        position:'relative',
        userSelect:'none'
    },
    root:{
        ...hBox('center','space-between'),
        backgroundColor: theme.palette.grey[100],
        borderRadius:100,
        paddingLeft:8,
        paddingRight:8,
        paddingTop:6,
        paddingBottom:6,
        userSelect:'none'
    },
    range:{
        position:'absolute',
        backgroundColor:'#E6E6E6',
        height:38,
        top:-2,
    },
    text:{
        fontWeight:500,
        zIndex:2,
        minWidth:20,
        cursor:'pointer'
    },
    slider:{
        position:'absolute',
        height:draggerSize,
        width:draggerSize,
        borderRadius:100,
        backgroundColor:theme.palette.primary.main,
        top:-6,
        zIndex:2,
        ...hBox('center','center'),
        cursor:'pointer'
    },
    sliderText:{
        color:'white',
        fontWeight:500,
        userSelect:'none'
    }
}))

export default forwardRef(FilterSettingRangeSlider)
