import React,{useState,useEffect} from 'react'
import { useTheme,makeStyles,createStyles, withStyles } from '@material-ui/core/styles';
import { hBox,vBox } from "gx-web-ui/utils/flex";
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import cx from 'classnames'
import {StyledTabs,StyledTab} from '@src/components/common/CustomTabSet'
import InputAdornment from '@material-ui/core/InputAdornment';
import {AUD} from '@src/utils/currency'
import chunk from 'lodash/chunk'
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import MenuItem from '@material-ui/core/MenuItem';
import {NSWStampDutyCalculator} from '@src/utils/calculator'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PrimaryCheckBox from '@src/components/common/PrimaryCheckBox';

const PriceRangeGroup = (props) => {
    const { selectOptions, priceRange, setPriceRange } = props
    const styles = useStyles()
    return (
        <div className={styles.root}>
            <TextField
                value={priceRange.min}
                onChange={e=>setPriceRange({
                    ...priceRange,
                    min:e.target.value
                })}
                fullWidth
                variant="outlined"
                margin='dense'
                select
                SelectProps={{
                    native: false,
                }}
                >
                {selectOptions && selectOptions.map((option)=>(
                    <MenuItem key={option.value} value={option.value} disabled={priceRange.max && option.value !=='any' && option.value >= priceRange.max}>
                    {option.title}
                    </MenuItem>
                ))}
            </TextField>
            <Typography className={styles.dash}>—</Typography>
            <TextField
                value={priceRange.max}
                onChange={e=>setPriceRange({
                    ...priceRange,
                    max:e.target.value
                })}
                fullWidth
                variant="outlined"
                margin='dense'
                select
                SelectProps={{
                    native: false,
                }}
                >
                {selectOptions && selectOptions.map((option)=>(
                    <MenuItem key={option.value} value={option.value} disabled={priceRange.min && option.value !=='any' && option.value <= priceRange.min}>
                    {option.title}
                    </MenuItem>
                ))}
            </TextField>
        </div>
    )
}

const useStyles = makeStyles((theme)=>({
    root:{
        ...hBox('center')
    },
    dash:{
        marginLeft:8,
        marginRight:8
    }
}))

export default PriceRangeGroup
