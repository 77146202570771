import React from 'react'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles';
export default withStyles(theme=>({
    root:{
        fontWeight:500,
        backgroundColor:'white',
        color:'black',
        paddingLeft:16,
        paddingRight:16,
        textTransform:'none',
        "&:hover":{
            backgroundColor:theme.palette.grey[300],
        },
        "&:disabled":{
            backgroundColor:theme.palette.grey[100],
            color:'black',
        }
    }
}))(props=><Button variant={'outlined'} {...props}/>)